import imageTarget from '../../images/image-target.png'
import * as React from 'react';
import Layoutas from '../components/Layout';
import './index.css';
import bg from '/images/bg.png'
export default function targetPage() {
  return (
    <Layoutas>
      <div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: 'url(' + bg + ')',
        backgroundSize: 'contain'
      }}>
        <div style={{
          width: '1200px',
          backdropFilter: 'blur(2px)',
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          paddingTop: 120,
          marginBottom: 200
        }}>
          <div className="font-bold text-2xl mb-2 text-center text-sky-800">กลุ่มเป้าหมายของกองทุนเพื่อความเสมอภาคทางการศึกษา</div>
          <div className='flex w-full flex justify-center .'>
            <img className='w-full' src={imageTarget} alt="image-target" />
          </div>

        </div>
      </div >
    </Layoutas >
  );
}
